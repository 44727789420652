.cke_editable {
    cursor: text;
    outline: 1px solid #d1d1d1 !important;
    border-radius: 3px;
    padding: 8px;
}

::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: #6d6c6c;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
    height: 6px;
    background-color: #F5F5F5;
}